import React from "react";
import "./content.css";

const AreasOfExpertise = () => {
    return (
        <div className="content">
            <div className="content_columns">
                <ul>
                    <li>AZURE</li>
                    <li>PCF, OPENSTACK</li>
                    <li>VMWARE, HYPER-V</li>
                    <li>HYBRID CLOUD</li>
                    <li>MIGRATION</li>
                    <li>CLOUDHEALTH</li>
                    <li>LINUX, WINDOWS</li>
                    <li>PACKER</li>
                    <li>DOCKER</li>
                    <li>KUBERNETES</li>
                </ul>
                <ul>
                    <li>AWS</li>
                    <li>ELK STACK</li>
                    <li>NEW RELIC</li>
                    <li>SNOWFLAKE</li>
                    <li>AWS CLOUD <br></br>FORMATION</li>
                    <li>ARM TEMPLATES</li>
                    <li>F5 LOAD <br></br>BALANCER</li>
                    <li>JENKINS</li>
                </ul>
                <ul>
                    <li>ANSIBLE</li>
                    <li>PYTHON</li>
                    <li>BITBUCKET</li>
                    <li>TERRAFORM</li>
                    <li>GITHUB ACTIONS</li>
                    <li>TOMCAT, IIS</li>
                    <li>NGINX</li>
                    <li>WEBLOGIC</li>
                    <li>BARRACUDA</li>
                    <li>GITLAB</li>
                </ul>
            </div>
            <div className="content_columns_responsive">
                <ul>
                    <li>AZURE</li>
                    <li>PCF, OPENSTACK</li>
                    <li>VMWARE, HYPER-V</li>
                    <li>HYBRID CLOUD</li>
                    <li>MIGRATION</li>
                    <li>CLOUDHEALTH</li>
                    <li>LINUX, WINDOWS</li>
                    <li>PACKER</li>
                    <li>DOCKER</li>
                    <li>KUBERNETES</li>
                    <li>AWS</li>
                    <li>ELK STACK</li>
                    <li>NEW RELIC</li>
                    <li>SNOWFLAKE</li>
                </ul>
                <ul>
                    <li>AWS CLOUD FORMATION</li>
                    <li>ARM TEMPLATES</li>
                    <li>F5 LOAD BALANCER</li>
                    <li>JENKINS</li>
                    <li>ANSIBLE</li>
                    <li>PYTHON</li>
                    <li>BITBUCKET</li>
                    <li>TERRAFORM</li>
                    <li>GITHUB ACTIONS</li>
                    <li>TOMCAT, IIS</li>
                    <li>NGINX</li>
                    <li>WEBLOGIC</li>
                    <li>BARRACUDA</li>
                    <li>GITLAB</li>
                </ul>
            </div>
        </div>
    );
};

export default AreasOfExpertise;
