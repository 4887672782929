import React from 'react'
import './styles/Title.css'

const Title = () => {
  return (
    <div className='title'>
        <h3>DevOps/Cloud Engineer</h3>
    </div>
  )
}

export default Title