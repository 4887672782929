import "./App.css";
import ResumePage from "./pages/ResumePage";

function App() {
    return (
        <div className="App">
            <div className="container_border">
                <div className="container">
                    <ResumePage />
                </div>
            </div>
        </div>
    );
}

export default App;
