import React from "react";
import './styles/SectionContent.css'

const JobInfo = ({ company, jobTitle, jobDuration }) => {
    return (
        <div className="jobinfo">
            <p>{company}</p>
            <div>
                <p>{jobTitle}</p>
                <p>{jobDuration}</p>
            </div>
        </div>
    );
};

export default JobInfo;
