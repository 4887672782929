import React from 'react'
import './content.css'
import JobInfo from '../JobInfo'
import EnvironmentInfo from '../EnvironmentInfo'

const RelevantWorkExperience = () => {
  return (
    <div>
        <JobInfo company="PowerSchool - Folsom, CA" jobTitle="Cloud Operations Engineer" jobDuration="Mar 2019 - Sep 2022"/>
        <div className='content'>
            <ul>
               <li>Worked in the Cloud Operations group to help manage day-to-day operations and administer large-scale infrastructure distributed across multiple co-locations and clouds.</li> 
               <li>Deploy and Support AWS and Azure Infrastructure by leveraging infrastructure as code to remove configuration drift using terraform.</li> 
               <li>Enhanced and developed custom terraform modules for deploying AWS and Azure Services.</li> 
               <li>Assisted in developing solutions and best practice implementation in AWS Services like IAM, EC2, Lambda, RDS, Redshift, EMR, Glue, S3, Kinesis Data Streams.</li> 
               <li>Setup and Manage Azure AD DS, AD DS, AWS Active Directory, Azure MFA, Azure AD Privileged Identity Management, PasswordSafe to provide secure access to the environment.</li> 
               <li>Support in administering and maintaining enterprise data cloud platform utilizing snowflake by creating pipelines to deploy database changes, setting up s3 polices for security controls.</li> 
               <li>Deploy and configure Azure network security infrastructure architecture by leveraging, Barracuda Cloud Gen Firewall, F5 Load Balancer, NSGs, Azure App Gateway, Qualys, Incapsula, and Password safe using terraform templates and python scripts.</li> 
               <li>Implemented data solutions using AWS Managed Services by deploying RDS, S3, Glue, Lambda, Athena, and Redshift to reduce operational workload.</li> 
               <li>Implemented solutions to forward New Relic Insights JSON data to Azure Data Lake and integrate with PowerBI to create dashboard for tracking KPI’s.</li> 
               <li>Develop self-service applications by leveraging PowerApps, Azure LogicApps, and PowerShell to increase productivity across teams and reduce ticket workload.</li> 
               <li>Served as an escalation engineer to help manage pagerduty alerts, troubleshoot, and resolve issues impacting application performance and outages.</li> 
               <li>Achieved zero-configuration drift by building a CI/CD pipeline on GitHub using terraform, packer, ansible for automated infrastructure deployment, and configuration in AWS and Azure.</li> 
               <li>Write Kubernetes YAML config file to spin up pods using different controllers like ReplicaSet, Deployments, DaemonSet.</li> 
            </ul>
        </div>
        <EnvironmentInfo content="RHEL, Ubuntu, Windows, AWS, Azure, VMWare, Hyper-V,  Terraform, Ansible, Packer, Docker, Kubernetes, PowerShell, Python, GitHub Actions, Tomcat, Passenger, Nginx, HA Proxy, Consul, MySQL, MongoDB, HBase, SQL, GitLab, Bitbucket,  New relic, FreshService" />

        <br></br>
        <JobInfo company="Sutter Health - Sacramento, CA" jobTitle="Systems Support Engineer" jobDuration="Jul 2013 - Dec 2017"/>
        <div className='content'>
            <ul>
               <li>Support 18 different AWS Accounts by setting up IAM users, groups and writing custom policies following the principle of least privilege.</li>
               <li>Installed and configured Jenkins master that served 30 different worker supporting eight different applications with various release life cycles and multiple CI pipelines setup on project branches.</li>
               <li>Integrated GIT into Jenkins to automate the code check-out process. Adding storage to the cluster disks and increasing/ decreasing the file system in RHEL.</li>
               <li>Worked on Multiple AWS instances, set the security groups, Elastic Load Balancer (ELB) and AMIs, Auto scaling to design cost-effective, fault-tolerant, and highly available systems.</li>
               <li>Created IAM policies for administration in AWS to assist type applications and teams across local geographical and remote areas. Transfer data from Datacenters to cloud using AWS Import/Export service.</li>
               <li>Implemented AWS API Gateway to manage, as an entry point for all the APIs.</li>
            </ul>
        </div>
        <EnvironmentInfo content="RHEL, Windows Server, Jenkins, AWS, Terraform, VSTS, Maven, MS Build, IIS, Tomcat, WebSphere, SQL, GIT, Bitbucket" />

        <br></br>
        <JobInfo company="Adventist Health - Roseville, CA" jobTitle="Identity Services" jobDuration="Mar 2012 - Mar 2013"/>
        <div className='content'>
            <ul>
               <li>Automated setup of secure user and group access utilizing active directory domain forest and developed scripts to modify specific user attributes.</li>
               <li>Understand and refine role-based security framework to produce schema designs and operational plans to enforce and maintain identity management workflows.</li>
               <li>Hands-on architect and developer of a comprehensive IAM system that addresses the challenges of a very complex development and production environment.</li>
            </ul>
        </div>
    </div>
  )
}

export default RelevantWorkExperience