import React, { useState, useEffect } from "react";
import './styles/VisitorCount.css';

const VisitorCount = () => {
    const [loading, setLoading] = useState(true);
    const [visitorCount, setVisitorCount] = useState(0);

    useEffect(() => {
        const fetchVisitorCount = async () => {
            try {
                const response = await fetch("https://kah6k67ot8.execute-api.us-east-2.amazonaws.com/visit");
                const text = await response.json();
                const dataArray = text.split(" ");
                const count = dataArray[dataArray.length - 1];
                setVisitorCount(count);
            } catch (error) {
                console.error("Error fetching visitor count:", error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchVisitorCount();
    }, []);
    
    return (
        <div className="visitor_count">
            {loading ? "Loading..." : `Resume Views: ${visitorCount}`}
        </div>
    );
    
};
export default VisitorCount;


