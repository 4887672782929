import React from 'react'
import './styles/SectionHeader.css'

const SectionHeader = ({title}) => {
  return (
    <div className='section_title'>
        <h2>{title}</h2>
        <div className='section_bar'></div>
    </div>
  )
}

export default SectionHeader