import React from 'react'
import SectionHeader from './SectionHeader'
import SectionContent from './SectionContent'

const SectionInfo = ({title, content}) => {
  return (
    <div className='section_info'>
        <SectionHeader title={title}/>
        <SectionContent content={content}/>
        <br></br>
    </div>
  )
}

export default SectionInfo