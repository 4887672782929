import React from 'react'
import './styles/ContactInfo.css'
import VisitorCount from './VisitorCount'

const ContactInfo = () => {
  return (
    <div className='contact_info'>
        <ul>
            <li>Jack Kussmann</li>
            <li>(530) 415-4334</li>
            <li>mail@jkussmann.com</li>
            <div className='wrapper'>
              <li className='button'>
                <a href='https://www.linkedin.com/in/jackkussmann'>
                  <div class="icon">
                    <i class="fab fa-linkedin-in"></i>
                  </div>
                </a>
              </li>
              <li className='button'>
                <a href='https://github.com/eLusivex'>
                  <div class="icon">
                    <i class="fab fa-github"></i>
                  </div>
                </a>
              </li>
            </div>
        </ul>
        <VisitorCount/>
    </div>
  )
}

export default ContactInfo