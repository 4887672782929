import React from 'react'
import './styles/SectionContent.css'

const EnvironmentInfo = ({content}) => {
  return (
    <div className='environment'>
        <p><strong>Enviroment:</strong> <em>{content}</em> </p>
    </div>
  )
}

export default EnvironmentInfo