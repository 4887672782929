import React from 'react'
import './styles/SectionContent.css'

const SectionContent = ({content}) => {
  return (
    <div className='section_content'>
        {content}
    </div>
  )
}

export default SectionContent