import React from "react";
import ContactInfo from "../components/ContactInfo";
import Title from "../components/Title";
import SectionInfo from "../components/SectionInfo";

// Content
import ProfessionalSummary from "../components/content/ProfessionalSummary";
import AreasOfExpertise from "../components/content/AreasOfExpertise";
import RelevantWorkExperience from "../components/content/RelevantWorkExperience"; 

const ResumePage = () => {
    return (
        <div>
            <ContactInfo />
            <Title />
            <SectionInfo title="Professional Summary" content={<ProfessionalSummary/>}/>
            <SectionInfo title="Areas of Expertise" content={<AreasOfExpertise/>}/>
            <SectionInfo title="Relevant Work Experience" content={<RelevantWorkExperience/>}/>
        </div>
    );
};

export default ResumePage;
