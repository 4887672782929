import React from 'react'
import './content.css'

const ProfessionalSummary = () => {
  return (
    <div className='content'>
        <ul>
            <li>Over 8+ years of experience in systems administration, identity management, automation, and cloud operations.</li>
            <li>Experience in designing, implementing complex infrastructure architectures on Azure, AWS, and hybrid cloud environments, including compute, network, storage, and monitoring.</li>
            <li>Partnered with development engineers and vendor specialists in seeking solutions for high available and fault tolerant deployments hosted in multiple availability zones and region.</li>
            <li>Experience with various DevOps tooling for Continuous Integration (CI), Configuration Management, Deployment Automation, Container Orchestration, and IaaC.</li>
            <li>Designed, configured, and deployed a multitude of applications on to the Azure platform utilizing VMs, Blobs, AD, Resource Groups, HD Insight Clusters, SQL, Azure DevOps, and ARM.</li>
            <li>Background with AWS cloud services like EC2, ELB, ECS, Auto Scaling, VPC, Route53, RDS, S3, IAM, SQS, DynamoDB, Elastic search, Lambda, Kinesis, Cloud Trail, and Cloud Watch.</li>
            <li>Strong ability to troubleshoot any issues generated while building, deploying, in production support and documenting the build and release process.</li>
            <li>Skilled in authoring and maintaining of PowerShell, Bash, and Python Scripts for scheduling services, backups to automate the administrative tasks and management.</li>
            <li>Experience in implementing advanced server protocols and utilities (DNS, SMTP, S/FTP, TCP/IP UDP, SSH, Samba), and enterprise-level DNS.</li>
            <li>Experience with deploying applications in an Agile/Scrum process by implementing various Branching, Cloud instance, and Container Strategies. </li>
            <li>Familiar with ETL and ELT design patterns with data warehousing solutins like AWS Redshift, Snowflake.</li>
        </ul>
    </div>
  )
}

export default ProfessionalSummary